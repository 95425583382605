export interface Speise {
  titel: string
  datum: string
  bild: string
  link?: string
  zutaten?: string[]
  zubereitung?: string
}

export interface SpeiseResult {
  speise: Speise
  bildURLs: string[]
  error: string
}

export enum ActionType {
  CREATE = 'create',
  DELETE = 'delete',
}

export interface ActionBase {
  path: string
  imgPath: string
}

export interface CreateAction extends ActionBase {
  type: ActionType.CREATE
  speise: Speise
}

export interface DeleteAction extends ActionBase {
  type: ActionType.DELETE
}

export type Action = CreateAction | DeleteAction
