import React from 'react'
// @ts-ignore
import placeholder from '../images/gatsby-icon.png'

interface Props {
  readonly titel: string
  readonly datum: string
  readonly link?: string
  readonly zutaten?: string[]
  readonly zubereitung?: string
  readonly bild?: string
  readonly type?: string
}

export const MDSpeise: React.SFC<Props> = ({
  titel,
  datum,
  link = '',
  zubereitung = '',
  zutaten = [],
  type = 'speise',
  bild = placeholder,
}: Props) =>
  // prettier-ignore
  <React.Fragment>
    {`---\n`}
    {`title: ${titel}\n`}
    {`date: ${datum}\n`}
    {`cover_image: ${bild}\n`}
    {`link: ${link}\n`}
    {`type: ${type}\n`}
    {`---\n`}
    {`\n##Zutaten\n`}
    {zutaten.map((zutat: string): string => `* ${zutat}\n`)}
    {`\n##Zubereitung\n`}
    {zubereitung}
  </React.Fragment>
