import React from 'react'
import { PageTransition } from '../components/transition'
import {
  createGitlabAction,
  extractImageData,
  loadActions,
  pushFilesToGitlab,
} from '../utils'
import { renderToStaticMarkup } from 'react-dom/server'
import { MDSpeise } from '../templates/mdspeise'
import { ActionType, CreateAction } from '../speise'

export const createSpeiseForGitlab = (action: CreateAction) => {
  const image = extractImageData(action.speise.bild)
  const actions = [
    createGitlabAction(
      ActionType.CREATE,
      action.path,
      renderToStaticMarkup(
        <MDSpeise
          titel={action.speise.titel}
          datum={action.speise.datum}
          link={action.speise.link}
          bild={`../${action.imgPath}`}
          zutaten={action.speise.zutaten}
          zubereitung={action.speise.zubereitung}
        />
      )
    ),
    createGitlabAction(action.type, action.imgPath, image.data, 'base64'),
  ]
  return pushFilesToGitlab('Neue Speise', actions)
}

const AktionslistePage = () => (
  <PageTransition>
    <h1>Aktionen</h1>
    <ul>
      {loadActions().map((action: CreateAction, i: number) => (
        <li key={i}>
          {action.speise.titel}
          <img src={action.speise.bild} />
        </li>
      ))}
    </ul>
    <button>Ausführen</button>
  </PageTransition>
)

export default AktionslistePage
